import { useContext } from 'react';

import { GlobalDispatchContext, GlobalStateContext } from '../context/global';
import { GlobalContext } from '../types/global-context';

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);

  if (context === undefined) {
    throw new Error('useGlobalState must be used inside of a GlobalProvider');
  }

  return context;
};

export const useGlobalDispatch = () => {
  const context = useContext(GlobalDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useGlobalDispatch must be used inside of a GlobalProvider'
    );
  }

  return context;
};

const useGlobalContext = (): GlobalContext => [
  useGlobalState(),
  useGlobalDispatch(),
];

export default useGlobalContext;
