// extracted by mini-css-extract-plugin
export var Footer = "footer-module--Footer--a5d58";
export var FooterSpanPreview = "footer-module--Footer--span-preview--87b6f";
export var Footer__wrapper = "footer-module--Footer__wrapper--054bc";
export var Footer__logo = "footer-module--Footer__logo--fc5d4";
export var Footer__loginButton = "footer-module--Footer__login-button--6867a";
export var Footer__nav = "footer-module--Footer__nav--de336";
export var Footer__navCentered = "footer-module--Footer__nav--centered--9e98f";
export var Footer__navMuted = "footer-module--Footer__nav--muted--d9e80";
export var Footer__navList = "footer-module--Footer__nav-list--25fe9";
export var FooterFixed = "footer-module--Footer--fixed--4df98";
export var Footer__navItem = "footer-module--Footer__nav-item--3ad9d";
export var Footer__navItemInline = "footer-module--Footer__nav-item--inline--dcd7c";
export var Footer__link = "footer-module--Footer__link--249ce";
export var Footer__linkMuted = "footer-module--Footer__link--muted--eaebf";
export var Footer__copyright = "footer-module--Footer__copyright--c1943";
export var menuItem = "footer-module--menu-item--b0c02";