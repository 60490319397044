import type React from 'react';
import { Template } from './templates';
import {
  COLOR_FIELD_NAMES,
  DESIGN_FIELD_NAMES,
  EDITABLE_FIELD_NAMES,
  FIELD_NAMES,
  HIDDEN_FIELD_NAMES,
  OPTIONS_FIELD_NAMES,
} from '../constants';

const inputTypes = [
  'text',
  'textarea',
  'email',
  'radio',
  'select',
  'file',
  'color',
  'hidden',
] as const;

export type InputType = typeof inputTypes[number];

/**
 * TODO: Most form status not used/needed, consider removing
 * Determines form state based on auth state. May be an important distinction
 * when transitioning between auth states.
 *
 * Initial = Guest user
 * User = Authenticated user
 */
export enum FormStatus {
  InitialPristine = 'INITIAL_PRISTINE', // Initial values are unchanged
  InitialDirty = 'INITIAL_DIRTY', // Initial values have changed
  UserPristine = 'USER_PRISTINE', // Saved user values are unchanged
  UserDirty = 'USER_DIRTY', // Saved user values have changed
  UserSaved = 'USER_SAVED', // Saved user values are now stale after submit
}

export type SocialUrlFieldName =
  | 'linkedinUrl'
  | 'facebookUrl'
  | 'twitterUrl'
  | 'instagramUrl'
  | 'youtubeUrl'
  | 'tiktokUrl';

export type FileFieldName = 'logo' | 'banner';

export type OptionsFieldName = typeof OPTIONS_FIELD_NAMES[number];

export type ColorFieldName = typeof COLOR_FIELD_NAMES[number];

export type HiddenFieldName = typeof HIDDEN_FIELD_NAMES[number];

export type FieldNameWithAdminControls = typeof EDITABLE_FIELD_NAMES[number];

export type DesignFieldName = typeof DESIGN_FIELD_NAMES[number];

export type TextFieldName = Exclude<
  FieldName,
  FileFieldName | OptionsFieldName | ColorFieldName | HiddenFieldName
>;

export type FieldName = keyof Signature & typeof FIELD_NAMES[number];

export const fontFamilies = [
  'Arial / Helvetica',
  'Comic Sans',
  'Calibri',
  'Courier',
  'Georgia',
  'Lucida Console / Monaco',
  'Lucida Sans',
  'Palatino',
  'System Font',
  'Tahoma',
  'Times / Times New Roman',
  'Trebuchet',
  'Verdana',
] as const;

export const fontSizes = ['Small', 'Medium', 'Large'] as const;

export type FontFamily = typeof fontFamilies[number];
export type FontSize = typeof fontSizes[number];

export interface GeneratorFormValues {
  signature: Signature;
  teamControls?: TeamControls;
}

export interface Signature {
  firstName?: string;
  lastName?: string;
  qualifications?: string;
  jobTitle?: string;
  department?: string;
  companyName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  streetAddress?: string;
  website?: string;
  linkedinUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  youtubeUrl?: string;
  tiktokUrl?: string;
  termsPrivacy?: string;
  logo?: FileList;
  logoHeight?: number;
  logoWidth?: number;
  logoLink?: string;
  logoURL?: string;
  banner?: FileList;
  bannerURL?: string;
  bannerLink?: string;
  template: Template;
  baseColour: string;
  highlightColour: string;
  linkColour: string;
  smallColour: string;
  fontFamily: FontFamily;
  fontSize: FontSize;
}

export interface MutableFieldConfig {
  /**
   * Toggles visibility of the field value in the signature HTML. This is purely
   * a control for Team Admins to specify which fields they and their staff do
   * not need to use - it produces no form field and no value to be applied to
   * the signature. Crucially, this prop has a different purpose to using
   * `type="hidden", which is the ideal choice for metadata fields the user
   * never needs to edit but may be required for core app functionality.
   */
  isEnabled?: boolean;
  /**
   * Toggles locked state of the field value. Allows Team Admins to control the
   * ability of their staff to change the value of this form field. The field
   * may have a default value set by the Team Admin which will be included in
   * the signature HTML but cannot be changed.
   */
  isLocked?: boolean;
}

export interface FieldConfig<N extends FieldName> extends MutableFieldConfig {
  value?: Signature[N];
  label?: string;
  description?: React.ReactNode;
  order: number;
  placeholder?: string;
  disabled?: boolean; // TBD if disabled fields feature needed
  required?: boolean; // TBD if required fields feature needed
}

export interface InputFieldBase<N extends FieldName, T = InputType>
  extends FieldConfig<N> {
  id: string;
  name: N;
  type: T;
  isValid?: boolean;
}

export type TextFieldProps = InputFieldBase<
  TextFieldName,
  'text' | 'textarea' | 'email'
>;

export type ColourFieldProps = InputFieldBase<
  'baseColour' | 'highlightColour' | 'linkColour' | 'smallColour',
  'color'
>;

export interface OptionsFieldProps
  extends InputFieldBase<OptionsFieldName, 'radio' | 'select'> {
  options: string[];
}

export interface FileFieldProps extends InputFieldBase<FileFieldName, 'file'> {
  accept: string[];
}

export type FormField =
  | InputFieldBase<FieldName>
  | OptionsFieldProps // extended
  | FileFieldProps; // extended

export type GeneratorFormConfig = {
  [K in FieldName]: FieldConfig<K>;
};

export type TeamControls = Record<
  FieldNameWithAdminControls,
  MutableFieldConfig
>;

export type TeamGeneratorForm = Partial<
  Record<FieldNameWithAdminControls, string>
>;

export type TeamDesign = Pick<Signature, DesignFieldName>;

export interface AccountFormValues {
  email: string;
  teamId?: string;
  teamName?: string;
}

export type ControlledTeamData = Record<
  'editable' | 'locked',
  TeamGeneratorForm
>;
