import React from 'react';
import * as styles from './mirosign-logo.module.scss';

const MirosignLogo = ({ siteTitle = '' }: MirosignLogoProps) => {
  return (
    <div className={styles.LogoWrapper}>
      <svg
        className={styles.Logo}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 103.85"
      >
        <path
          className={styles.Logo__fill}
          d="M197.75,29.71a18.08,18.08,0,0,0-9.26-2.27,21,21,0,0,0-8.68,1.76,17,17,0,0,0-6.52,5.06,14.74,14.74,0,0,0-5.48-5.06,16.05,16.05,0,0,0-7.65-1.76A17.47,17.47,0,0,0,152.77,29,16.14,16.14,0,0,0,147,33.43V27.85l-13.65,1.66V75.72h14.07V48.43A9.34,9.34,0,0,1,149.62,42a7.27,7.27,0,0,1,5.68-2.42,6.57,6.57,0,0,1,5.17,2.22,9,9,0,0,1,2,6.15V75.72H176.6V48.43A9.39,9.39,0,0,1,178.77,42a7.18,7.18,0,0,1,5.59-2.42,6.74,6.74,0,0,1,5.22,2.22,8.83,8.83,0,0,1,2,6.15V75.72h14.06V46.15a22.9,22.9,0,0,0-2-9.92A14.7,14.7,0,0,0,197.75,29.71Z"
        />
        <path
          className={styles.Logo__fill}
          d="M227.52,5a8.86,8.86,0,0,0-4.39-1.13,8.55,8.55,0,0,0-7.45,4.18,8,8,0,0,0-1.13,4.19,7.76,7.76,0,0,0,1.13,4.14,8.71,8.71,0,0,0,7.45,4.13,8.76,8.76,0,0,0,4.39-1.14,8.4,8.4,0,0,0,3.15-3,7.77,7.77,0,0,0,1.14-4.14,8,8,0,0,0-1.14-4.19A8.39,8.39,0,0,0,227.52,5Z"
        />
        <polygon
          className={styles.Logo__fill}
          points="215.99 75.72 230.16 75.72 230.16 27.75 215.99 29.51 215.99 75.72"
        />
        <path
          className={styles.Logo__fill}
          d="M254.45,35.71V27.85l-13.23,1.66V75.72h14.06V54.11q0-13.74,14.27-13.75V27.75a19.81,19.81,0,0,0-9.31,2A13.57,13.57,0,0,0,254.45,35.71Z"
        />
        <path
          className={styles.Logo__fill}
          d="M310.85,30.64a27.5,27.5,0,0,0-26.16,0,25.09,25.09,0,0,0-9.4,9,24.47,24.47,0,0,0,0,25.12,25.09,25.09,0,0,0,9.4,9,27.5,27.5,0,0,0,26.16,0,24.93,24.93,0,0,0,9.36-9,23.79,23.79,0,0,0,3.41-12.56,24.07,24.07,0,0,0-3.41-12.61A24.83,24.83,0,0,0,310.85,30.64Zm-2.22,28a12.41,12.41,0,0,1-4.5,4.6A12.1,12.1,0,0,1,297.77,65a12.38,12.38,0,0,1-6.41-1.71,12.24,12.24,0,0,1-4.55-4.65,13.47,13.47,0,0,1,0-12.92,11.9,11.9,0,0,1,4.55-4.6,12.63,12.63,0,0,1,6.41-1.66,12.35,12.35,0,0,1,6.36,1.66,12.06,12.06,0,0,1,4.5,4.55,13.67,13.67,0,0,1,0,13Z"
        />
        <path
          className={styles.Logo__fill}
          d="M349.05,46.77,347,46.05A9.62,9.62,0,0,1,343.21,44a3.65,3.65,0,0,1-1.08-2.58,3.39,3.39,0,0,1,1.18-2.69,5.16,5.16,0,0,1,3.47-1,19.76,19.76,0,0,1,5.43.93,34.46,34.46,0,0,1,6.15,2.49l1.76-10.55a37.09,37.09,0,0,0-7-2.38,31.3,31.3,0,0,0-6.93-.83,19.55,19.55,0,0,0-8.74,1.87,14,14,0,0,0-5.84,5.17,13.71,13.71,0,0,0-2.07,7.44,14.07,14.07,0,0,0,2.95,9,17.54,17.54,0,0,0,8.43,5.59l1.86.72a11.13,11.13,0,0,1,4.44,2.12,4,4,0,0,1-.26,6.05,6.59,6.59,0,0,1-4.08,1.14,19,19,0,0,1-6-1,39.11,39.11,0,0,1-6.61-3l-1.66,10.45a37.55,37.55,0,0,0,7.6,3.1,29,29,0,0,0,8,1.14,20.79,20.79,0,0,0,9.15-1.92,14.39,14.39,0,0,0,6.16-5.37,14.84,14.84,0,0,0,2.17-8,13.52,13.52,0,0,0-3.16-9.15Q355.36,49,349.05,46.77Z"
        />
        <path
          className={styles.Logo__fill}
          d="M381.67,5a8.86,8.86,0,0,0-4.39-1.13,8.55,8.55,0,0,0-7.45,4.18,8,8,0,0,0-1.13,4.19,7.76,7.76,0,0,0,1.13,4.14,8.71,8.71,0,0,0,7.45,4.13,8.76,8.76,0,0,0,4.39-1.14,8.32,8.32,0,0,0,3.15-3A7.77,7.77,0,0,0,386,12.24a8,8,0,0,0-1.14-4.19A8.32,8.32,0,0,0,381.67,5Z"
        />
        <polygon
          className={styles.Logo__fill}
          points="370.14 75.72 384.31 75.72 384.31 27.75 370.14 29.51 370.14 75.72"
        />
        <path
          className={styles.Logo__fill}
          d="M430.11,33.75A19,19,0,0,0,423.59,29a19.9,19.9,0,0,0-8.16-1.66,22.07,22.07,0,0,0-11.63,3.21,23.55,23.55,0,0,0-8.48,8.79,25.32,25.32,0,0,0,0,24.5,23.55,23.55,0,0,0,8.48,8.79,22.06,22.06,0,0,0,11.63,3.2,19.9,19.9,0,0,0,8.16-1.65,19.67,19.67,0,0,0,6.52-4.65v4q0,6.83-3.57,10.75t-10.6,3.93q-9.3,0-18.3-7.24l-2.48,11.58A36.49,36.49,0,0,0,417,99.81a29.58,29.58,0,0,0,14-3.2,22.48,22.48,0,0,0,9.3-9,27.27,27.27,0,0,0,3.26-13.49V27.85l-13.44,1.66Zm-1.45,24.19a12.2,12.2,0,0,1-4.44,4.55A12,12,0,0,1,418,64.14a12.22,12.22,0,0,1-6.25-1.65,11.72,11.72,0,0,1-4.45-4.55,12.79,12.79,0,0,1-1.6-6.31,12.57,12.57,0,0,1,1.65-6.31,12.27,12.27,0,0,1,4.45-4.54,12.31,12.31,0,0,1,12.35,0,12.11,12.11,0,0,1,4.5,4.54,12.82,12.82,0,0,1,0,12.62Z"
        />
        <path
          className={styles.Logo__fill}
          d="M497.88,36.59a15.51,15.51,0,0,0-6.1-6.77,18.06,18.06,0,0,0-9.46-2.38,20.43,20.43,0,0,0-8,1.55,17.31,17.31,0,0,0-6.26,4.44V27.85l-13.64,1.66V75.72h14.06V49.15a9.76,9.76,0,0,1,2.48-7,8.5,8.5,0,0,1,6.51-2.63,8,8,0,0,1,6.1,2.42,9.37,9.37,0,0,1,2.28,6.67V75.72H500V46.88A23.49,23.49,0,0,0,497.88,36.59Z"
        />
        <path
          className={styles.Logo__fill}
          d="M51.93,0C29.17,0,16.52,0,8.26,8.26S0,29.17,0,51.93s0,35.4,8.26,43.66,20.91,8.26,43.67,8.26,35.4,0,43.66-8.26,8.27-20.91,8.27-43.66,0-35.41-8.27-43.67S74.68,0,51.93,0ZM17,39.28a8.9,8.9,0,0,1,1.13-4.4,8.47,8.47,0,0,1,3.05-3.15,8.07,8.07,0,0,1,4.19-1.14,7.77,7.77,0,0,1,4.14,1.14,8.58,8.58,0,0,1,3,3.15,8.9,8.9,0,0,1,1.13,4.4,8.65,8.65,0,0,1-4.13,7.44,7.77,7.77,0,0,1-4.14,1.14,8.07,8.07,0,0,1-4.19-1.14A8.55,8.55,0,0,1,17,39.28Zm70.2,34H20.72L19,59.1H87.23Zm0-26.85H43.63L41.92,32.25H87.23Z"
        />
      </svg>
      <span className="sr-only">{siteTitle}</span>
    </div>
  );
};

interface MirosignLogoProps {
  siteTitle?: string;
}

export default MirosignLogo;
