import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import * as styles from './toast-message.module.scss';

export type ToastType = 'success' | 'info' | 'warning' | 'error';

const toastIconForType: Record<ToastType, IconDefinition> = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle,
};

/**
 * Simple wrapper for toast messages to apply a little custom styling and render
 * an icon according to the message type.
 */
const ToastMessage = ({ type, message }: ToastMessageProps) => (
  <div className={styles.ToastMessage}>
    <FontAwesomeIcon
      icon={toastIconForType[type]}
      size="2x"
      className={styles.ToastMessage__icon}
    />
    <span className={styles.ToastMessage__text}>{message}</span>
  </div>
);

export interface ToastMessageProps {
  type: ToastType;
  message: string;
}

export default ToastMessage;
