import React from 'react';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';

import * as styles from './footer.module.scss';
import MirosignLogo from './mirosign-logo';
import NavLinks from './nav-links';
import Button from './button';
import { PageRouteConfig } from '../types/routes';
import {
  footerNavRoutes,
  mainNavRoutes,
  termsNavRoutes,
} from '../constants/navigation';
import { pagePath } from '../constants/routes';

/**
 * Common site footer component, made flexible to use across all pages. Default
 * footer renders in traditional colophon style for main brochure pages, but on
 * Generator page it has only core links and renders in a fixed corner position.
 *
 * @param isGenerator - True when the current path is a Generator page
 * @param siteTitle - Name of the site from Gatsby config
 */
const Footer = ({
  isGenerator,
  isTeamGenerator,
  siteTitle = '',
}: FooterProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const labelsAscending: (
    ...args: [PageRouteConfig, PageRouteConfig]
  ) => number = ({ title: a }, { title: b }) => (a < b ? -1 : a > b ? 1 : 0);

  const MainLinks = () => (
    <NavLinks
      items={mainNavRoutes}
      itemClassName={styles.Footer__navItem}
      linkClassName={styles.Footer__link}
      className={styles.Footer__navList}
    />
  );

  const CommonLinks = () => (
    <NavLinks
      items={
        isGenerator
          ? footerNavRoutes
          : [...footerNavRoutes].sort(labelsAscending)
      }
      itemClassName={classNames(styles.Footer__navItem, {
        [styles.Footer__navItemInline]: isGenerator,
      })}
      linkClassName={classNames(styles.Footer__link)}
      isInline={isGenerator}
      className={styles.Footer__navList}
    />
  );

  const TermsLinks = () => (
    <NavLinks
      items={termsNavRoutes}
      itemClassName={styles.Footer__navItem}
      linkClassName={classNames(styles.Footer__link, styles.Footer__linkMuted)}
      className={styles.Footer__navList}
    />
  );

  const teamGeneratorFooter = isAuthenticated ? null : (
    <>
      <Button
        role="link"
        onClick={() =>
          loginWithRedirect({ appState: { returnTo: pagePath.index } })
        }
        label="Login"
        className={styles.Footer__loginButton}
      />
      &nbsp;with your administrator account if you need to edit this signature
      design
    </>
  );

  return (
    <footer
      className={classNames(styles.Footer, {
        [styles.FooterFixed]: isGenerator || isTeamGenerator,
        [styles.FooterSpanPreview]: isTeamGenerator,
      })}
    >
      <div className={styles.Footer__wrapper}>
        {!isGenerator && (
          <div className={styles.Footer__logo}>
            <MirosignLogo siteTitle={siteTitle} />
          </div>
        )}

        <nav
          className={classNames(styles.Footer__nav, {
            [styles.Footer__navCentered]: isTeamGenerator,
            [styles.Footer__navMuted]: isGenerator,
          })}
        >
          {!isGenerator && <MainLinks />}

          {isTeamGenerator ? teamGeneratorFooter : <CommonLinks />}

          {!isGenerator && <TermsLinks />}
        </nav>

        {!isGenerator && (
          <div className={styles.Footer__copyright}>
            &copy; Copyright {new Date().getFullYear()} Designbooth Ltd
          </div>
        )}
      </div>
    </footer>
  );
};

interface FooterProps {
  isGenerator?: boolean;
  isTeamBuilder?: boolean;
  isTeamGenerator?: boolean;
  siteTitle?: string;
}

export default Footer;
