import React, { useEffect, useReducer } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UploadApiResponse } from 'cloudinary';
import { Slide, ToastContainer } from 'react-toastify';
import { Formik, FormikHelpers } from 'formik';

import { GlobalDispatchContext, GlobalStateContext } from '../context/global';
import { FORM_FIELDS } from '@common/constants/form';
import { Auth0User } from '@common/types/user';
import useAPIHelper from '../hooks/api';
import useSubscriptionContext from '../hooks/subscription-context';
import useDebounce from '../hooks/debounce';
import { isBrowser } from '../utils/browser';
import toast from '../utils/toast';
import Spinner from './spinner';
import { GlobalActionType, GlobalReducer } from '../types/global-context';
import {
  FieldName,
  FormField,
  FormStatus,
  GeneratorFormValues,
  Signature,
} from '@common/types/form';
import { GeneratorMode } from '../types/generator';
import { basicGeneratorFormSchema } from '../utils/form-schema';
import {
  LOGO_MAX_HEIGHT,
  LOGO_MAX_WIDTH,
  BANNER_MAX_HEIGHT,
  BANNER_MAX_WIDTH,
} from '@common/constants/templates';

type FieldDescriptionsMap = Partial<Record<FieldName, React.ReactNode>>;

const fieldDescriptionsMap: FieldDescriptionsMap = {
  logo: (
    <>
      Max file size: 1MB &nbsp;
      <a
        href="https://mirosign.com/documentation/how-do-i-add-a-logo-to-my-email-signature/"
        target="_blank"
        rel="noreferrer"
      >
        More information
      </a>
    </>
  ),
  banner: (
    <>
      Max file size: 1MB &nbsp;
      <a
        href="https://mirosign.com/documentation/how-do-i-add-a-banner-to-the-email-signature/"
        target="_blank"
        rel="noreferrer"
      >
        More information
      </a>{' '}
      <br />
      Optimal size: 335px wide &times; 100px high
    </>
  ),
};

const formFields: FormField[] = FORM_FIELDS.map((field) => ({
  ...field,
  description: fieldDescriptionsMap[field.name],
}));

export const FORM_INITIAL_VALUES_DEFAULT = {
  signature: formFields.reduce(
    (valueMap, { value, name }) => ({
      ...valueMap,
      [name]: value,
    }),
    {} as Signature
  ),
};

/**
 * Performs state update actions based on the action type and returns an updated
 * state object. Throws an error when a requested action is not available.
 *
 * @param state - Previous state to be modified
 * @param action - An action object
 * @param action.type = The type of action to perform
 * @param action[key: string] - The state data to update
 */
const globalReducer: GlobalReducer = (state, action) => {
  switch (action.type) {
    case GlobalActionType.SetFormInitialStatus:
      return {
        ...state,
        formInitialStatus: action.formInitialStatus,
      };

    case GlobalActionType.SetFormInitialValues:
      return {
        ...state,
        formInitialValues:
          action?.formInitialValues || FORM_INITIAL_VALUES_DEFAULT,
        formInitialStatus: FormStatus.UserPristine,
      };

    case GlobalActionType.SetFormValidationSchema:
      return {
        ...state,
        formValidationSchema: action.formValidationSchema,
      };

    case GlobalActionType.SetGeneratorMode:
      return {
        ...state,
        mode: action.mode,
      };

    case GlobalActionType.SetTeamName:
      return {
        ...state,
        teamName: action.teamName,
      };

    case GlobalActionType.SetIsAppLoading:
      return {
        ...state,
        isAppLoading: action.isAppLoading,
      };

    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
  }
};

/**
 * Initialises and serves up the global reducer to consumer components. Wraps
 * the whole app in the Formik provider so that Formik Context is available
 * across all page routes. Fetches and updates existing user data.
 *
 * @param children - The rest of the app
 */
const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    isAppLoading: false,
    mode: GeneratorMode.Personal,
    formFields,
    formInitialValues: FORM_INITIAL_VALUES_DEFAULT,
    formInitialStatus: FormStatus.InitialPristine,
    formValidationSchema: basicGeneratorFormSchema,
  });

  const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth0();

  const {
    getAccessToken,
    makeFetchUserConfig,
    makeFetchLambdaPrivateConfig,
    useAuth0UserAPI,
    useCloudinaryAPI,
    useLambdaAPI,
  } = useAPIHelper();

  const { isActive, subscriptionEnd } = useSubscriptionContext();

  const [{ loading: isDataLoading }, fetchUser] = useAuth0UserAPI<Auth0User>(
    {},
    { manual: true }
  );

  const [, fetchImageSignature] = useLambdaAPI<string>(
    { url: '/image-signature', method: 'GET' },
    { manual: true }
  );

  const [, fetchImageUpload] = useCloudinaryAPI<UploadApiResponse>(
    {
      url: '/image/upload',
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    { manual: true }
  );

  const isAppLoadingDebounced = useDebounce(
    isDataLoading || isAuthLoading,
    500
  );

  useEffect(() => {
    dispatch({
      type: GlobalActionType.SetIsAppLoading,
      isAppLoading: isAppLoadingDebounced,
    });
  }, [isAppLoadingDebounced]);

  /**
   * Asynchronously fetches from the Cloudinary API to sign the upload request,
   * then proceeds to upload the new image on success.
   *
   * @param accessToken - Scoped user auth token
   * @param logo - Logo file input value from Formik
   * @param banner - Banner file input value from Formik
   */
  const fetchImageUploadAsync = async (
    accessToken: string,
    ...[banner, logo]: (FileList | undefined)[]
  ) => {
    if (!isActive) {
      toast.error('You must subscribe to a plan to upload images!');
      return [];
    }

    const buildImageTransformationOptions = (fieldName: 'banner' | 'logo') => {
      const commonOptions = [
        'f_auto',
        'fl_preserve_transparency',
        'q_auto:eco',
      ];

      const dimensions = [];

      switch (fieldName) {
        case 'banner':
          dimensions.push(
            `w_${BANNER_MAX_WIDTH}`,
            `h_${BANNER_MAX_HEIGHT}`,
            'c_lpad',
            'b_white',
            'g_north_west'
          );
          break;

        // TODO: Create a map of templates to logo sizes where they differ, or accept that one size fits all
        case 'logo':
          dimensions.push(
            `w_${LOGO_MAX_WIDTH}`,
            `h_${LOGO_MAX_HEIGHT}`,
            'c_fit'
          );
      }

      return [...dimensions, ...commonOptions].join(',');
    };

    const buildImageUploadParams = (
      fieldName: 'banner' | 'logo'
    ): Record<string, unknown> => ({
      use_filename: true,
      filename_override: fieldName,
      unique_filename: false,
      folder: user?.sub?.replace('auth0|', ''),
      access_control: JSON.stringify([
        {
          access_type: 'anonymous',
          end: new Date(subscriptionEnd! * 1000).toISOString(),
        },
      ]),
      allowed_formats: 'png,gif,jpg,jpeg',
      overwrite: true,
      invalidate: true,
      transformation: buildImageTransformationOptions(fieldName),
      timestamp: Date.now(),
    });

    const buildImageUploadFormData = (
      file: FileList,
      params: Record<string, unknown>,
      signature: string
    ) => {
      // Cloudinary REST API upload must be sent as FormData 🤔
      const data = new FormData();

      // Add the signed params to data
      Object.keys(params).forEach((key) => {
        data.append(key, `${params[key]}`);
      });

      // Add the rest of the required data values, including the signature
      data.append('file', file[0]);
      data.append('api_key', process.env.GATSBY_CLOUDINARY_API_KEY);
      data.append('signature', signature);

      return data;
    };

    const prepareImageUploadData = async (
      fieldName: 'banner' | 'logo',
      file?: FileList
    ) => {
      if (!file?.length) return;

      const params = buildImageUploadParams(fieldName);

      // Attempt to generate signature from params. If the current user should not
      // have access to image uploads this will return an error instead.
      const { data: signature } = await fetchImageSignature(
        await makeFetchLambdaPrivateConfig({ params, token: accessToken })
      );

      return buildImageUploadFormData(file, params, signature);
    };

    const [bannerData, logoData] = [
      await prepareImageUploadData('banner', banner),
      await prepareImageUploadData('logo', logo),
    ];

    return [
      bannerData ? await fetchImageUpload({ data: bannerData }) : undefined,
      logoData ? await fetchImageUpload({ data: logoData }) : undefined,
    ];
  };

  /**
   * Asynchronously submits the form with current data if the user is logged in.
   * First uploads new image, transforms values to an object compatible with the
   * Auth0 user profile, then waits for a response from the save function.
   * Resets the form with new data on success.
   */
  const handleSubmit = async (
    { signature, teamControls }: GeneratorFormValues,
    { resetForm }: FormikHelpers<GeneratorFormValues>
  ) => {
    if (!isAuthenticated) return;

    // Get a scoped access token for the authenticated user
    const accessToken = await getAccessToken();

    // Attempt to upload new images to Cloudinary if aby
    const [bannerResponse, logoResponse] = await fetchImageUploadAsync(
      accessToken,
      signature.banner,
      signature.logo
    );

    const logoProps = {
      // Send updated images if any, otherwise use existing
      bannerURL: bannerResponse?.data.secure_url || signature.bannerURL,
      logoURL: logoResponse?.data.secure_url || signature.logoURL,
      logoHeight: logoResponse?.data.height || signature.logoHeight,
      logoWidth: logoResponse?.data.width || signature.logoWidth,
      // Never send the actual value from the file upload fields, it causes bugs
      banner: undefined,
      logo: undefined,
    };

    // Create a DTO ready to save to the user profile
    const userData: Auth0User = {
      user_metadata: {
        signature: {
          ...signature,
          ...logoProps,
        },
        teamControls,
      },
    };

    // Attempt to save signature data
    const response = await fetchUser(
      await makeFetchUserConfig({
        data: userData,
        method: 'PATCH',
        token: accessToken,
      })
    );

    // Handle errors
    if (!response.data.user_metadata?.signature) {
      toast.error('Something went wrong. Please try again.');
      throw new Error('Oops, something went wrong');
    }

    const { user_metadata } = response.data;

    // Reinitialise the form with the newly saved data
    resetForm({
      values: {
        signature: user_metadata?.signature,
        teamControls: user_metadata?.teamControls,
      },
      status: FormStatus.UserSaved,
    });

    toast.success('Signature changes saved!');
  };

  if (!isBrowser()) return null;

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <Formik
          validationSchema={state.formValidationSchema}
          initialValues={state.formInitialValues}
          initialStatus={state.formInitialStatus}
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
        >
          {({ isSubmitting }) => (
            <>
              <Spinner isLoading={isAppLoadingDebounced || isSubmitting} />
              {children}
            </>
          )}
        </Formik>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeButton={false}
          closeOnClick
          newestOnTop
          pauseOnHover
          pauseOnFocusLoss
          transition={Slide}
        />
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalProvider;
