import React from 'react';
import { Link } from 'gatsby';

import * as styles from './header.module.scss';
import MainNav from './main-nav';
import MirosignLogo from './mirosign-logo';
import { GENERATOR_MODE_META } from './generator.const';
import useGlobalContext from '../hooks/global-context';
import { pagePath } from '../constants/routes';

/**
 * Main site header/navigation used on all layouts
 */
const Header = ({ isGenerator, siteTitle = '' }: HeaderProps) => {
  const [{ mode, teamName, isAppLoading }] = useGlobalContext();

  const { description } = GENERATOR_MODE_META[mode];

  const generatorTitle =
    typeof description === 'function' ? description(teamName) : description;

  return (
    <header className={styles.Header}>
      <div className={styles.Header__logo}>
        <Link
          to={pagePath.index}
          className={styles.Header__logoLink}
          title="Return to homepage"
        >
          <MirosignLogo siteTitle={siteTitle} />
        </Link>
      </div>

      <div className={styles.Header__nav}>
        {isGenerator && !isAppLoading && (
          <div className={styles.Header__generatorTitle}>{generatorTitle}</div>
        )}
        <MainNav />
      </div>
    </header>
  );
};

interface HeaderProps {
  isGenerator?: boolean;
  siteTitle?: string;
}

export default Header;
