import type { Dispatch, SetStateAction } from 'react';
import { TeamConfig } from '@common/types/user';
import {
  FieldName,
  FormField,
  TeamControls,
  TeamDesign,
} from '@common/types/form';
import { SidebarView } from '../components/sidebar.const';

export enum GeneratorMode {
  Personal = 'PERSONAL',
  TeamBuilder = 'TEAM_BUILDER',
  TeamGenerator = 'TEAM_GENERATOR',
}

export interface GeneratorState {
  fields: FormField[];
  controls?: TeamControls;
  design?: TeamDesign;
  config?: TeamConfig;
}

export type GeneratorForm = {
  [key in SidebarView]: ReadonlyArray<FieldName>;
};

export type GeneratorDispatch = Dispatch<SetStateAction<GeneratorState>>;

export type IGeneratorContext = [GeneratorState, GeneratorDispatch];
