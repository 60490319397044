export const templates = [
  'Template 1',
  'Template 2',
  'Template 3',
  'Template 4',
  'Template 5',
  'Template 6',
  'Template 7',
  'Template 8',
  'Template 9',
  'Template 10',
] as const;

export const LOGO_MAX_HEIGHT = 160;
export const LOGO_MAX_WIDTH = 160;

export const BANNER_MAX_HEIGHT = 100;
export const BANNER_MAX_WIDTH = 335;
