import { createContext } from 'react';
import { GlobalDispatch, GlobalState } from '../types/global-context';

export const GlobalStateContext = createContext<GlobalState | undefined>(
  undefined
);

export const GlobalDispatchContext = createContext<GlobalDispatch | undefined>(
  undefined
);
