import { Stripe } from 'stripe';
import { GeneratorFormValues, Signature, TeamControls } from './form';

export type PaymentStatus =
  | 'COMPLETE' // Subscription selected and payment succeeded
  | 'ERROR' // An error occurred during the payment process
  | 'OPEN' // No payment made for any active subscription
  | 'OVERDUE'; // Subscription expired and payment failed or was not not made

export type SubscriptionStatus =
  | 'ACTIVE' // User has an active subscription
  | 'WILL_CANCEL' // User has cancelled their renewal (still active)
  | 'CANCELLED' // User subscription has been cancelled (not active)
  | 'EXPIRED' // Subscription has expired, user did not renew
  | 'INACTIVE'; // TBD - Not currently used, may be useful in future?

export type SubscriptionType = 'INDIVIDUAL' | 'TEAM' | 'BESPOKE';

export type UnsubscribeAction = 'IMMEDIATE' | 'PERIOD_END';

export interface TeamConfig {
  parentId: string;
  teamId: string;
  teamName?: string;
}

export type UserMetadata = GeneratorFormValues;

export interface AppMetadata {
  customerId?: Stripe.Subscription['customer'] | null;
  /**
   * @deprecated Use `canUploadMedia`
   */
  isLogoUploadEnabled?: boolean;
  canUploadMedia?: boolean;
  paymentStatus?: PaymentStatus;
  subscriptionCancelledAt?: Stripe.Subscription['canceled_at'];
  subscriptionId?: string | Stripe.Subscription | null;
  subscriptionPeriodEnd?: Stripe.Subscription['current_period_end'];
  subscriptionStatus?: SubscriptionStatus;
  subscriptionType?: SubscriptionType;
  teamConfig?: TeamConfig;
  userRole?: 'TEAM_ADMIN' | 'TEAM_MEMBER';
}

export interface Auth0User {
  connection?: string;
  email?: string;
  name?: string;
  app_metadata?: AppMetadata;
  user_metadata?: UserMetadata;
}

/**
 * Auth0 user_metadata and app_metadata does not come through in a user profile
 * automatically, it has to be injected with a rule. Auth0 require custom claims
 * to be namespaced with an URL-style string, but it does not have to be a real
 * URL since it will never be called.
 */
export enum Auth0MirosignCustomNamespace {
  CustomerId = 'https://mirosign.app/customerId',
  Plan = 'https://mirosign.app/plan',
  Signature = 'https://mirosign.app/signature',
  Status = 'https://mirosign.app/status',
  SubscriptionCancelledAt = 'https://mirosign.app/subscriptionCancelledAt',
  SubscriptionEnd = 'https://mirosign.app/subscriptionEnd',
  TeamControls = 'https://mirosign.app/teamControls',
  TeamId = 'https://mirosign.app/teamId',
  TeamName = 'https://mirosign.app/teamName',
}

/**
 * Extends the typing of am Auth0 `user` object (returned on auth) to include
 * our custom namespaces claims
 */
export type Auth0UserIdTokenClaims = {
  sub: string;
  [Auth0MirosignCustomNamespace.CustomerId]: string | null;
  [Auth0MirosignCustomNamespace.Plan]?: SubscriptionType;
  [Auth0MirosignCustomNamespace.Signature]: Signature | null;
  [Auth0MirosignCustomNamespace.Status]?: SubscriptionStatus;

  // Unix timestamp in seconds. Multiply by 1000 to use with Date() functions.
  [Auth0MirosignCustomNamespace.SubscriptionCancelledAt]?: number;
  [Auth0MirosignCustomNamespace.SubscriptionEnd]?: number;
  [Auth0MirosignCustomNamespace.TeamControls]?: TeamControls;
  [Auth0MirosignCustomNamespace.TeamId]?: string;
  [Auth0MirosignCustomNamespace.TeamName]?: string;
};
