import { Dispatch, SetStateAction, useState } from 'react';

export enum STORAGE_KEY {
  GUEST_FORM_DATA = 'guestFormData',
  TEAM_MEMBER_FORM_DATA = 'teamMemberFormData',
  AUTH_STATE = 'mirosign:auth',
}

/**
 * Custom hook to interact with a single localStorage key
 *
 * @param key - The localStorage key to read/set
 * @param initialValue - A value to be set if it does not already exist
 */
const useLocalStorage = <T = unknown | undefined>(
  key: STORAGE_KEY,
  initialValue?: T
): [T, Dispatch<SetStateAction<T>>] => {
  const fromLocalStorage = () => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState(fromLocalStorage());

  const setValue: Dispatch<SetStateAction<T>> = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
