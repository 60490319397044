import { Routes } from '../types/routes';

export const MIROSIGN_DOMAIN = 'https://mirosign.com';

// These routes are external to the application but within the Mirosign domain
export const domainRoutes = [
  `${MIROSIGN_DOMAIN}/how-it-works/`,
  `${MIROSIGN_DOMAIN}/benefits/`,
  `${MIROSIGN_DOMAIN}/documentation/`,
  `${MIROSIGN_DOMAIN}/blog/`,
  `${MIROSIGN_DOMAIN}/contact/`,
  `${MIROSIGN_DOMAIN}/terms/`,
  `${MIROSIGN_DOMAIN}/privacy/`,
  `${MIROSIGN_DOMAIN}/install/`,
] as const;

const [
  howItWorks,
  benefits,
  documentation,
  blog,
  contact,
  terms,
  privacy,
  install,
] = domainRoutes;

export const domainPath = {
  howItWorks,
  benefits,
  documentation,
  blog,
  contact,
  terms,
  privacy,
  install,
};

// These routes are internal to the application
export const pageRoutes = [
  '/',
  '/pricing/',
  '/account/',
  '/order-received/',
  '/login/',
  '/404/',
] as const;

const [index, pricing, account, orderReceived, login, notFound] = pageRoutes;

export const pagePath = {
  index,
  pricing,
  account,
  orderReceived,
  login,
  notFound,
};

export const routes: Routes = [
  {
    title: 'Design your signature',
    path: index,
    navGroup: ['main'],
  },
  {
    label: 'Pricing',
    title: 'Pricing',
    subtitle: 'Choose your plan',
    path: pricing,
    navGroup: ['main'],
  },
  {
    title: 'How it works',
    path: howItWorks,
    isExternal: true,
    navGroup: ['main'],
  },
  {
    title: 'Benefits',
    path: benefits,
    isExternal: true,
    navGroup: ['main'],
  },
  {
    title: 'My Account',
    path: account,
    navGroup: ['main', 'main-active'],
    isPrivate: true,
  },
  {
    title: 'Log in',
    path: login,
  },
  {
    title: 'Your order',
    path: orderReceived,
  },
  {
    title: 'Not Found',
    path: notFound,
  },
  {
    title: 'Documentation',
    path: documentation,
    isExternal: true,
    navGroup: ['footer'],
  },
  {
    title: 'Installation',
    path: install,
    isExternal: true,
    navGroup: ['footer'],
  },
  {
    title: 'Blog',
    path: blog,
    isExternal: true,
    navGroup: ['footer'],
  },
  {
    title: 'Contact us',
    path: contact,
    isExternal: true,
    navGroup: ['footer'],
  },
  {
    title: 'Terms and conditions',
    path: terms,
    isExternal: true,
    navGroup: ['terms'],
  },
  {
    title: 'Privacy policy',
    path: privacy,
    isExternal: true,
    navGroup: ['terms'],
  },
];
