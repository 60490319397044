/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import Layout from './src/components/layout';

// Import global styles
import './src/styles/global.scss';
import GlobalProvider from './src/components/global-provider';
import SubscriptionProvider from './src/components/subscription-provider';
import { navigate } from 'gatsby';

/**
 * Wraps every page component with a Layout component and
 * passes the router location props to it. Saves doing the
 * same in each individual page.
 *
 * @param {React.ReactNode} element
 * @param {{ location }} props - Router location data
 * @returns {React.ReactNode}
 */
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

/**
 * Use Gatsby's navigate method to replace the URL and persist state into the
 * page route.
 */
const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', { replace: true, state: appState });
};

/**
 * Wraps the app root in a global context provider to help pass top-level data
 * around the app. Also configures and wraps in the Auth0 provider.
 *
 * @param {React.ReactNode} element
 * @returns {React.ReactNode}
 */
export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    authorizeTimeoutInSeconds={120}
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    audience={`https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`}
    scope="openid profile email read:current_user update:current_user_metadata"
    redirectUri={`${window.location.origin}/callback/`}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens
  >
    <SubscriptionProvider>
      <GlobalProvider>{element}</GlobalProvider>
    </SubscriptionProvider>
  </Auth0Provider>
);
