import { SidebarView } from './sidebar.const';
import { GeneratorForm, GeneratorMode } from '../types/generator';
import { FieldName } from '@common/types/form';

export const editViewFieldNames: ReadonlyArray<FieldName> = [
  'firstName',
  'lastName',
  'qualifications',
  'jobTitle',
  'department',
  'companyName',
  'email',
  'phone',
  'mobile',
  'streetAddress',
  'website',
  'linkedinUrl',
  'facebookUrl',
  'twitterUrl',
  'instagramUrl',
  'youtubeUrl',
  'tiktokUrl',
  'termsPrivacy',
] as const;

export const imagesViewFieldNames: ReadonlyArray<FieldName> = [
  'logo',
  'logoHeight',
  'logoWidth',
  'logoLink',
  'logoURL',
  'banner',
  'bannerURL',
  'bannerLink',
] as const;

export const templatesViewFieldNames: ReadonlyArray<FieldName> = [
  'template',
] as const;

export const designViewFieldNames: ReadonlyArray<FieldName> = [
  'baseColour',
  'highlightColour',
  'linkColour',
  'smallColour',
  'fontFamily',
  'fontSize',
] as const;

export const SIDEBAR_VIEW_FIELD_MAP: GeneratorForm = {
  [SidebarView.Edit]: editViewFieldNames,
  [SidebarView.Images]: imagesViewFieldNames,
  [SidebarView.Templates]: templatesViewFieldNames,
  [SidebarView.Design]: designViewFieldNames,
};

export const GENERATOR_MODE_META = {
  [GeneratorMode.Personal]: {
    description: 'Email signature generator',
  },
  [GeneratorMode.TeamBuilder]: {
    description: 'Team generator builder',
  },
  [GeneratorMode.TeamGenerator]: {
    // TODO: Need to update this, but not sure where the name will be defined
    description: (teamName?: string) => `${teamName || 'Your'} team generator`,
  },
};
