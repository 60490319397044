import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import * as styles from './spinner.module.scss';

/**
 * Spinner to show when the app is performing certain functions and needs to
 * block parts of the DOM.
 *
 * @param isLoading - An external value to show/hide the spinner
 * @param size - Optional FontAwesome size prop
 */
const Spinner = ({ isLoading, size = '5x' }: SpinnerProps) => {
  return isLoading ? (
    <div className={styles.Spinner}>
      <FontAwesomeIcon icon={faCircleNotch} spin={true} size={size} />
    </div>
  ) : null;
};

export interface SpinnerProps {
  isLoading: boolean;
  size?: SizeProp;
}

export default Spinner;
