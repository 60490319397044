import { routes } from './routes';
import { NavGroup, PageRouteConfig, RouteConfigMap } from '../types/routes';

/**
 * Returns an array function to check for configs that appear in a specific
 * navigation group.
 */
const navGroupIs = (group: NavGroup) => ({ navGroup }: PageRouteConfig) =>
  navGroup?.includes(group);

/**
 * Returns an array reducer function and initial value (to be used as args for
 * Array.reduce(...args()). Takes a route config item and maps it to an object
 * keyed by its path.
 */
const mapConfigToPath = <T = RouteConfigMap>(): [
  (map: T, config: PageRouteConfig) => T,
  T
] => [
  (map, config) => ({
    ...map,
    [config.path]: config,
  }),
  {} as T,
];

export const mainNavRoutes = routes.filter(navGroupIs('main'));
export const footerNavRoutes = routes.filter(navGroupIs('footer'));
export const termsNavRoutes = routes.filter(navGroupIs('terms'));
export const mainActiveNavRoutes = routes.filter(navGroupIs('main-active'));
export const routeConfigMap = routes.reduce(...mapConfigToPath());
