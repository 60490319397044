import React from 'react';
import classNames from 'classnames';

import * as styles from './button.module.scss';

/**
 * Custom button component with various options
 *
 * @param children - Overrides label if used, supports HTML content
 * @param className - Append a custom classname to the button
 * @param isDisabled - Blocks the button from click events and execution
 * @param isBlock - Renders a full-width block button when true
 * @param isFormSubmit - Sets the submit type on the button, for use in forms
 * @param label - Text to show on the button, alternative to children
 * @param onClick - Click handler for the button
 * @param role - Set a different accessible role to 'button'
 * @param size - Use a preset button size
 * @param theme - Use a preset button theme
 */
const Button = ({
  isBlock,
  className,
  children,
  isDisabled,
  isFormSubmit,
  label,
  onClick,
  role,
  size = 'regular',
  theme = 'primary',
}: ButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      role={role}
      type={isFormSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={classNames(styles.Button, {
        [styles.ButtonSmall]: size === 'small',
        [styles.ButtonLarge]: size === 'large',
        [styles.ButtonBlock]: isBlock,
        [styles.ButtonSkeleton]: theme === 'secondary',
        [className as string]: !!className,
      })}
    >
      {children || label}
    </button>
  );
};

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  isBlock?: boolean;
  isFormSubmit?: boolean;
  label?: string;
  onClick: () => void;
  role?: 'link' | string;
  size?: 'small' | 'regular' | 'large';
  theme?: 'primary' | 'secondary';
}

export default Button;
