import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import ToastMessage from '../components/toast-message';

export enum TOAST_IDS {
  UnsavedChanges,
  ValidationError,
}

const success = (message: string, options?: ToastOptions) =>
  toast.success(<ToastMessage type="success" message={message} />, options);

const warning = (message: string, options?: ToastOptions) =>
  toast.warning(<ToastMessage type="warning" message={message} />, options);

const info = (message: string, options?: ToastOptions) =>
  toast.info(<ToastMessage type="info" message={message} />, options);

const error = (message: string, options?: ToastOptions) =>
  toast.error(<ToastMessage type="error" message={message} />, options);

const validationError = () =>
  error(
    "We couldn't process your signature data. Please correct validation errors and try again.",
    { autoClose: false, toastId: TOAST_IDS.ValidationError }
  );

const unsavedChanges = () =>
  warning(
    'You have unsaved changes. Please save your signature before downloading.',
    { autoClose: false, toastId: TOAST_IDS.UnsavedChanges }
  );

const appToast = {
  success,
  warning,
  info,
  error,
  validationError,
  unsavedChanges,
};

export default appToast;
