import { boolean, mixed, number, object, string } from 'yup';
import { LOGO_MAX_WIDTH, templates } from '@common/constants/templates';
import { fontFamilies, fontSizes } from '@common/types';

const KILOBYTE = 1024;
const IMAGE_UPLOAD_MAX_KB = 1024;
const IMAGE_UPLOAD_MIN_PX = LOGO_MAX_WIDTH;

const validateFileSize = (files?: FileList): boolean => {
  return (
    !files ||
    (!!files.length && files[0].size <= IMAGE_UPLOAD_MAX_KB * KILOBYTE)
  );
};

const validateFileDimensions = (files?: FileList): Promise<boolean> => {
  if (!files?.length) return Promise.resolve(true);

  return new Promise<boolean>((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const image = new Image();

      image.src = e.target?.result as string;

      image.onload = (e) => {
        if (!e.target) resolve(true);

        const loadedImage = e.target as HTMLImageElement;
        const width = loadedImage.width;

        if (width < IMAGE_UPLOAD_MIN_PX) resolve(false);

        resolve(true);
      };
    };
  });
};

const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

const maxLength = {
  text: 200,
  textarea: 2000,
};

const validationMessages = {
  url:
    'Please enter a valid URL, including the protocol, e.g. https://mirosign.com.',
  email: 'Please enter a valid email address.',
  maxLengthText: `Please enter a maximum of ${maxLength.text} characters.`,
  maxLengthTextarea: `Please enter a maximum of ${maxLength.textarea} characters.`,
  hexCode: 'Please enter a valid hex code.',
  maxSizeFile: `Please select a file size under ${IMAGE_UPLOAD_MAX_KB}KB.`,
  minDimensionsFile: `Please select a file with minimum ${IMAGE_UPLOAD_MIN_PX}px width.`,
};

const textFieldSchema = string().optional().max(maxLength.text);
const urlFieldSchema = string().url(validationMessages.url).optional();

const hexCodeFieldSchema = string()
  .matches(hexCodeRegex, validationMessages.hexCode)
  .required();

const fileFieldSchema = mixed()
  .nullable()
  .notRequired()
  .test('fileSize', validationMessages.maxSizeFile, validateFileSize);

/**
 * "Design" fields focus on look and feel.
 *
 * They are always unlocked/visible for the standard Personal Generator, and the
 * Generator Builder. Team Generators never render these fields, they are set by
 * the Team Admin.
 */
const designFormSchema = object({
  banner: fileFieldSchema,
  bannerLink: urlFieldSchema,
  bannerURL: urlFieldSchema,
  logo: fileFieldSchema.test(
    'fileDimensions',
    validationMessages.minDimensionsFile,
    validateFileDimensions
  ),
  logoHeight: number().optional(),
  logoWidth: number().optional(),
  logoLink: urlFieldSchema,
  logoURL: urlFieldSchema,
  template: string()
    .oneOf([...templates])
    .required(),
  baseColour: hexCodeFieldSchema,
  highlightColour: hexCodeFieldSchema,
  linkColour: hexCodeFieldSchema,
  smallColour: hexCodeFieldSchema,
  fontFamily: string()
    .oneOf([...fontFamilies])
    .required(),
  fontSize: string()
    .oneOf([...fontSizes])
    .required(),
});

/**
 * "Editable" fields are optional, text-based inputs.
 *
 * They are always unlocked/visible for the standard Personal Generator, and the
 * Generator Builder. Team Generators render these fields according to the
 * unlocked/visible state set by the Team Admin.
 */
const editableFormSchema = object({
  firstName: textFieldSchema,
  lastName: textFieldSchema,
  qualifications: textFieldSchema,
  jobTitle: textFieldSchema,
  department: textFieldSchema,
  companyName: textFieldSchema,
  email: string().email(validationMessages.email).optional(),
  phone: textFieldSchema,
  mobile: textFieldSchema,
  streetAddress: textFieldSchema,
  website: urlFieldSchema,
  linkedinUrl: urlFieldSchema,
  facebookUrl: urlFieldSchema,
  twitterUrl: urlFieldSchema,
  instagramUrl: urlFieldSchema,
  youtubeUrl: urlFieldSchema,
  tiktokUrl: urlFieldSchema,
  termsPrivacy: string().optional().max(maxLength.textarea),
});

const mutableFieldSchema = object({
  isEnabled: boolean().optional(),
  isLocked: boolean().optional(),
});

const teamControlsFormSchema = object({
  firstName: mutableFieldSchema,
  lastName: mutableFieldSchema,
  qualifications: mutableFieldSchema,
  jobTitle: mutableFieldSchema,
  department: mutableFieldSchema,
  companyName: mutableFieldSchema,
  email: mutableFieldSchema,
  phone: mutableFieldSchema,
  mobile: mutableFieldSchema,
  streetAddress: mutableFieldSchema,
  website: mutableFieldSchema,
  linkedinUrl: mutableFieldSchema,
  facebookUrl: mutableFieldSchema,
  twitterUrl: mutableFieldSchema,
  instagramUrl: mutableFieldSchema,
  youtubeUrl: mutableFieldSchema,
  tiktokUrl: mutableFieldSchema,
  termsPrivacy: mutableFieldSchema,
});

export const basicGeneratorFormSchema = object({
  signature: editableFormSchema.concat(designFormSchema),
});

export const teamGeneratorFormSchema = object({
  signature: editableFormSchema,
});

export const generatorBuilderFormSchema = object({
  signature: editableFormSchema.concat(designFormSchema),
  teamControls: teamControlsFormSchema,
});

export type GeneratorFormSchema =
  | typeof basicGeneratorFormSchema
  | typeof teamGeneratorFormSchema
  | typeof generatorBuilderFormSchema;
