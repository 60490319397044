import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import * as styles from './layout.module.scss';
import Header from './header';
import Footer from './footer';
import { pagePath } from '../constants/routes';
import useGlobalContext from '../hooks/global-context';
import { GeneratorMode } from '../types/generator';
import useMediaQuery from '../hooks/media-query';

const Layout = ({ children, location }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [{ mode }] = useGlobalContext();

  const isMobile = useMediaQuery('(max-width: 900px)');

  const isGenerator =
    !isMobile &&
    (location.pathname === pagePath.index ||
      location.pathname.substring(0, 2) === '/t');

  const isTeamBuilder = isGenerator && mode === GeneratorMode.TeamBuilder;
  const isTeamGenerator = isGenerator && mode === GeneratorMode.TeamGenerator;

  const layoutClasses = classNames(styles.Layout, {
    [styles.LayoutNoScroll]: isGenerator,
    [styles.LayoutBuilderMode]: isTeamBuilder,
  });

  return (
    <div className={layoutClasses}>
      <Header
        isGenerator={isGenerator}
        siteTitle={data.site.siteMetadata.title}
      />

      <main className={styles.Layout__main}>{children}</main>

      <Footer
        isGenerator={isGenerator}
        isTeamBuilder={isTeamBuilder}
        isTeamGenerator={isTeamGenerator}
        siteTitle={data.site.siteMetadata.title}
      />
    </div>
  );
};

interface LayoutProps {
  children: React.ReactNode;
  location: Location;
}

export default Layout;
