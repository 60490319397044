import axios, { Method } from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { useAuth0 } from '@auth0/auth0-react';

import { FormErrorResponse } from '@common/types';

/**
 * A custom hook to share some API logic and commonly used functions.
 */
const useAPIHelper = () => {
  const useLambdaAPI = makeUseAxios({
    axios: axios.create({ baseURL: '/.netlify/functions' }),
  });

  const useAuth0UserAPI = makeUseAxios({
    axios: axios.create({
      baseURL: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users`,
    }),
  });

  const useCloudinaryAPI = makeUseAxios({
    axios: axios.create({
      baseURL: `https://api.cloudinary.com/v1_1/${process.env.GATSBY_CLOUDINARY_NAME}`,
    }),
  });

  const { getAccessTokenSilently, user } = useAuth0();

  /**
   * Returns an access token for the current Auth0 user, to be used in API calls
   * requiring auth. Has a default access scope but can be overridden where
   * different/additional scopes are required.
   *
   * @param scope - Scopes (permissions) required for the token
   */
  const getAccessToken = async <T extends unknown>({
    scope,
  }: FetchConfig<T> = {}) =>
    await getAccessTokenSilently({
      audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`,
      scope: scope || 'read:current_user update:current_user_metadata',
    });

  /**
   * Returns an auth header with a bearer token for authenticated requests.
   * Provide an access token or required scopes to create one on demand.
   *
   * @param scope - Required scopes for a new access token
   * @param token - An existing access token if already generated
   */
  const getAuthHeader = async <T extends unknown>({
    scope,
    token,
  }: FetchConfig<T>) => ({
    Authorization: `Bearer ${token || (await getAccessToken({ scope }))}`,
  });

  /**
   * Returns an Axios config object for use in API calls targeting the Auth0
   * user endpoint. For authenticated requests, provide an access token or
   * required scopes to create one on demand.
   *
   * @param data - Payload to be sent in the request
   * @param method - HTTP method
   * @param scope - Required scopes for a new access token
   * @param token - An existing access token if already generated
   */
  const makeFetchUserConfig = async <T extends unknown>({
    data,
    method,
    scope,
    token,
  }: FetchConfig<T>) => ({
    url: `/${user?.sub}`,
    headers: { ...(await getAuthHeader({ scope, token })) },
    method,
    data,
  });

  /**
   * Returns an Axios config object for use in API calls targeting any lambda
   * endpoint.  For authenticated requests, provide an access token or required
   * scopes to create one on demand.
   *
   * @param data - Payload to be sent in the request
   * @param method - HTTP method
   * @param params - Key/value object of query params
   * @param scope - Required scopes for a new access token
   * @param token - An existing access token if already generated
   */
  const makeFetchLambdaPrivateConfig = async <T extends unknown>({
    data,
    method,
    params,
    scope,
    token,
  }: FetchConfig<T> = {}) => ({
    headers: {
      ...(await getAuthHeader({ scope, token })),
      user: user?.sub,
    },
    method,
    params,
    data,
  });

  /**
   * Type assertion, returns true if the error object return by an API call
   * contains form errors.
   */
  const hasFormErrors = (
    error: unknown
  ): error is Required<FormErrorResponse> =>
    !!(error as FormErrorResponse).response?.data.formErrors;

  return {
    useLambdaAPI,
    useAuth0UserAPI,
    useCloudinaryAPI,
    getAccessToken,
    getAuthHeader,
    hasFormErrors,
    makeFetchUserConfig,
    makeFetchLambdaPrivateConfig,
  };
};

export interface FetchConfig<T extends unknown> {
  data?: T;
  method?: Method;
  params?: T;
  scope?: string;
  token?: string;
}

export default useAPIHelper;
