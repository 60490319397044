import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import * as styles from './main-nav.module.scss';
import Button from './button';
import NavLinks from './nav-links';
import { mainActiveNavRoutes, mainNavRoutes } from '../constants/navigation';
import { GeneratorMode } from '../types/generator';
import useGlobalContext from '../hooks/global-context';
import useSubscriptionContext from '../hooks/subscription-context';
import useLocalStorage, { STORAGE_KEY } from '../hooks/local-storage';

/**
 * Renders the main navigation for the app header. Includes a link for user auth
 * redirect, which is handled externally via Auth0.
 */
const MainNav = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { isActive } = useSubscriptionContext();
  const [{ mode }] = useGlobalContext();
  const [, setAuthState] = useLocalStorage(STORAGE_KEY.AUTH_STATE, {
    isAuthenticated: false,
  });

  useEffect(() => {
    if (isAuthenticated) setAuthState({ isAuthenticated });
  }, [isAuthenticated]);

  const handleLogout = () => {
    setAuthState({ isAuthenticated: false });
    logout({ returnTo: window.location.origin });
  };

  const handleAuthClick = () =>
    isAuthenticated
      ? handleLogout()
      : loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });

  const isTeamGenerator = mode === GeneratorMode.TeamGenerator;

  const navItems = () => {
    switch (true) {
      case isTeamGenerator:
        return [];

      case isActive:
        return mainActiveNavRoutes;

      default:
        return mainNavRoutes;
    }
  };

  return (
    <nav className={styles.MainNav}>
      <NavLinks
        items={navItems()}
        itemClassName={styles.MainNav__item}
        linkClassName={styles.MainNav__link}
        activeLinkClassName={styles.MainNav__linkActive}
        isInline
      />

      {!isTeamGenerator && (
        <Button
          role="link"
          onClick={handleAuthClick}
          label={isAuthenticated ? 'Log out' : 'Log in'}
          className={styles.MainNav__auth}
        />
      )}
    </nav>
  );
};

export default MainNav;
