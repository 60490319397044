import { Dispatch, Reducer } from 'react';
import { FormField, FormStatus, GeneratorFormValues } from '@common/types/form';
import { GeneratorMode } from './generator';
import { GeneratorFormSchema } from '../utils/form-schema';

export enum GlobalActionType {
  SetFormInitialStatus = 'SET_FORM_INITIAL_STATUS',
  SetFormInitialValues = 'SET_FORM_INITIAL_VALUES',
  SetFormValidationSchema = 'SET_FORM_VALIDATION_SCHEMA',
  SetGeneratorMode = 'SET_GENERATOR_MODE',
  SetIsAppLoading = 'SET_IS_APP_LOADING',
  SetTeamName = 'SET_TEAM_NAME',
}

export type GlobalActionValue = {
  [GlobalActionType.SetFormInitialStatus]: FormStatus;
  [GlobalActionType.SetFormInitialValues]: GeneratorFormValues;
  [GlobalActionType.SetFormValidationSchema]: GeneratorFormSchema;
  [GlobalActionType.SetIsAppLoading]: boolean;
  [GlobalActionType.SetTeamName]: string;
};

type V = GlobalActionValue;

export type GlobalState = {
  mode: GeneratorMode;
  formFields: FormField[];
  formInitialStatus: V[GlobalActionType.SetFormInitialStatus];
  formInitialValues: V[GlobalActionType.SetFormInitialValues];
  formValidationSchema: V[GlobalActionType.SetFormValidationSchema];
  isAppLoading: V[GlobalActionType.SetIsAppLoading];
  teamName?: V[GlobalActionType.SetTeamName];
};

export type GlobalAction =
  | {
      type: GlobalActionType.SetFormInitialStatus;
      formInitialStatus: V[GlobalActionType.SetFormInitialStatus];
    }
  | {
      type: GlobalActionType.SetFormInitialValues;
      formInitialValues?: V[GlobalActionType.SetFormInitialValues];
    }
  | {
      type: GlobalActionType.SetFormValidationSchema;
      formValidationSchema: V[GlobalActionType.SetFormValidationSchema];
    }
  | {
      type: GlobalActionType.SetGeneratorMode;
      mode: GeneratorMode;
    }
  | {
      type: GlobalActionType.SetIsAppLoading;
      isAppLoading: V[GlobalActionType.SetIsAppLoading];
    }
  | {
      type: GlobalActionType.SetTeamName;
      teamName: V[GlobalActionType.SetTeamName];
    };

export type GlobalReducer = Reducer<GlobalState, GlobalAction>;

export type GlobalDispatch = Dispatch<GlobalAction>;

export type GlobalContext = [GlobalState, GlobalDispatch];
