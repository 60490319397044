import { useState, useEffect } from 'react';

/**
 * Lightweight hook to debounce a supplied value
 * Sets a timeout and subsequently clears and restarts it again if the value
 * updates before the timeout expires.
 *
 * @param value - The value to debounce and return
 * @param delay - The time in milliseconds to debounce by
 */
const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [delay, value]);

  return debouncedValue;
};

export default useDebounce;
