import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import SubscriptionContext from '../context/subscription';
import { SubscriptionState } from '@common/types/subscription';
import { Auth0MirosignCustomNamespace } from '@common/types/user';

/**
 * Stores reusable logic and accessors for the current user's subscription.
 * Lifts and centralises various subscription-related values from the Auth0 data
 * - these are stored against a namespace so access look a bit ugly when used
 * around the app.
 */
const SubscriptionProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>(
    { isLoading: true }
  );

  useEffect(() => {
    if (!isAuthenticated || !user) return;

    setSubscriptionState({
      isLoading: isLoading || !user || !isAuthenticated,
      currentPlan: user[Auth0MirosignCustomNamespace.Plan],
      isActive:
        user[Auth0MirosignCustomNamespace.Status] !== 'INACTIVE' &&
        user[Auth0MirosignCustomNamespace.Status] !== 'EXPIRED' &&
        user[Auth0MirosignCustomNamespace.Status] !== 'CANCELLED' &&
        user[Auth0MirosignCustomNamespace.SubscriptionEnd] * 1000 > Date.now(),
      signature: user[Auth0MirosignCustomNamespace.Signature] || null,
      status: user[Auth0MirosignCustomNamespace.Status],
      subscriptionCancelledAt:
        user[Auth0MirosignCustomNamespace.SubscriptionCancelledAt],
      subscriptionEnd: user[Auth0MirosignCustomNamespace.SubscriptionEnd],
      teamControls: user[Auth0MirosignCustomNamespace.TeamControls],
      teamId: user[Auth0MirosignCustomNamespace.TeamId],
      teamName: user[Auth0MirosignCustomNamespace.TeamName],
    });
  }, [isAuthenticated, user]);

  return (
    <SubscriptionContext.Provider value={subscriptionState}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
