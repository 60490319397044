import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faImage,
  faThList,
  faTint,
} from '@fortawesome/free-solid-svg-icons';

export enum SidebarView {
  Edit = 'EDIT',
  Images = 'IMAGES',
  Templates = 'TEMPLATES',
  Design = 'DESIGN',
}

export type SidebarTab = {
  icon: IconProp;
  view: SidebarView;
  title: string;
};

export const SIDEBAR_TABS: SidebarTab[] = [
  {
    view: SidebarView.Edit,
    icon: faEdit,
    title: 'Content',
  },
  {
    view: SidebarView.Images,
    icon: faImage,
    title: 'Images',
  },
  {
    view: SidebarView.Templates,
    icon: faThList,
    title: 'Template',
  },
  {
    view: SidebarView.Design,
    icon: faTint,
    title: 'Design',
  },
];
